<template>
  <div class="home">

    <breadcrumb :items="breadcrumb" />

    <v-row>
      <v-col>

        <v-card flat class="card_action">

            <v-row align-items="center">
              <v-col class="flex_end">
                <v-btn
                  color="primary"
                  depressed
                  tile
                  @click="addInvoice()"
                >
                  Dodaj
                </v-btn>
              </v-col>
            </v-row>

        </v-card>
        
        <v-form
          ref="form" 
          v-model="valid"
          lazy-validation
        >
          <v-card>
            <v-card-text>
              <v-row>
                <v-col>


                  <v-text-field
                    v-model="name"
                    label="Numer faktury"
                    color="primary"
                    :rules="$store.state.rules.not_null"
                  ></v-text-field>

                  <v-text-field
                    v-model="price"
                    label="Kwota (netto)"
                    color="primary"
                    :rules="$store.state.rules.not_null"
                  ></v-text-field>

                  <v-switch
                    v-model="paid"
                    inset
                    :label="'Zapłacona: ' + (paid?'TAK':'NIE')"
                    class="classic_switch"
                  ></v-switch>

                  <!-- <v-select
                    v-model="selected_customer"
                    :items="customers"
                    item-text="name"
                    item-value="id"
                    class="classic_select"
                    chips
                    label="Klient"
                    :rules="$store.state.rules.not_null"
                  ></v-select> -->

                  <v-autocomplete
                    v-model="selected_customer"
                    :items="customers"
                    item-text="name"
                    item-value="id"
                    no-data-text="Brak wyników"
                    label="Klient"
                    :rules="$store.state.rules.not_null"
                  ></v-autocomplete>

                  <v-menu
                    v-model="date_picker"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="date_created"
                        label="Kiedy wystawiono fakturę"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date_created"
                      @input="date_picker = false; securityCheck()"
                      locale="pl-pl"
                      color="primary"
                    ></v-date-picker>
                  </v-menu>

                  <v-menu
                    v-model="date_picker2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="date_to"
                        label="Do kiedy płatność"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date_to"
                      :min="date_created"
                      @input="date_picker2 = false"
                      locale="pl-pl"
                      color="primary"
                    ></v-date-picker>
                  </v-menu>

                  <v-file-input
                    v-model="invoice_pdf"
                    label="Plik z fakturą"
                    truncate-length="20"
                    :rules="$store.state.rules.not_null"
                  ></v-file-input>

                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-form>

      </v-col>
    </v-row>
    
  </div>
</template>

<script>
export default {
  data: () => ({
    valid: false,
    id: 0,
    breadcrumb: [
      {
        text: 'Pulpit',
        disabled: false,
        to: '/panel',
      },
      {
        text: 'Lista faktur',
        disabled: false,
        to: '/faktury',
      },
      {
        text: 'Nowa faktura',
        disabled: true,
        to: '/faktura',
      },
    ],

    paid: false,
    date_picker: false,
    date_picker2: false,
    date_created: new Date().toISOString().substr(0, 10),
    date_to: new Date().toISOString().substr(0, 10),
    name: '',
    price: '',
    selected_customer: 0,
    invoice_pdf: null,

    customers: [],
    search: ''
  }),
  methods: {
    // Sprawdzanie poprawności dat
    securityCheck(){
      if(this.date_created > this.date_to) this.date_to = this.date_created;
    },
    addInvoice() {
      if(this.$refs.form.validate()) {
        this.$store.commit('loader');

        let formData = new FormData();
        formData.append("number_invoice", this.name);
        formData.append("status", this.paid);
        formData.append("price", this.price);
        formData.append("date_create", this.date_created);
        formData.append("date_invoice", this.date_to);
        formData.append("assign_user", this.selected_customer);
        formData.append("file", this.invoice_pdf);

        this.$axios({url: this.$store.state.api +'/invoice', data: formData, method: 'POST', headers: {'Content-Type': 'multipart/form-data'} })
          .then(resp => {
            this.$router.push('/faktury');
            this.$store.commit("snackbar", {
              text: "Dodano fakturę",
              color: "primary",
              btn_color: "white"
            });
          })
          .catch(err => {
            this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
          })
          .then (() => {
            this.$store.commit('loader_off');
          })
      } else this.$store.commit('wrong_form_snackbar');
    },
  },
  mounted(){
    this.$store.commit('loader');
    this.$axios({url: this.$store.state.api +'/customer', data: {}, method: 'GET' })
      .then(resp => {
        this.customers = resp.data;
        // this.data = resp.data;
        console.log(resp.data);
      })
      .catch(err => {
        this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
      })
      .then (() => {
        this.$store.commit('loader_off');
      })
  }
};
</script>

<style lang="scss" scoped>
</style>